<template>
  <div>
    <div v-show="editMode && (newArticle || editArticle)">
      <div class="flex mt-8 mb-4">
        <label class="block text-xl italic" for="fname">Rubrik:</label>
        <input type="text" v-model=theModel.heading :class="{ inputok: headerOk }" class="border border-solid 
          rounded border-gray-400 text-xl ml-2 w-80" placeholder="Rubrik" 
          id="fname" name="fnta bortrrrame"> <span class="ml-2 -mt-2">*</span>
      </div>
      <div>
        <tiptap v-model=theModel.story></tiptap>
      </div>
      <div class="mt-6 flex flex-col">
        <table class="border-spacing-y-8">
          <tr>
            <td class="text-right w-px pb-4"><label class="block italic" for="proposedTags">Kategori:</label></td>
            <td class="text-left pb-4">
              <select :class="{ inputok: categoryOk }" class="ml-2 border border-gray-300 rounded w-30" v-model="theModel.category">
                <option disabled value="">Välj kategori</option>
                <option v-for="option in options" :key=option.id :value="option.text">{{option.text}}</option>
              </select><span class="ml-2 relative -top-2">*</span>
            </td>
            
          </tr>
          <tr>
            <td class="text-right w-px pb-4"><label class="block italic">År:</label></td>
            <td class="text-left pb-4">
              <input :class="{ inputok: validYear }" class="ml-2 border border-gray-300 rounded w-20" v-model="theModel.year" placeholder="Ange år"/>
              <span class="ml-2 italic text-gray-500">Ex: "1993", "70-tal", "1800-tal"</span>
            </td>
          </tr>
          <tr></tr>
          <td class="text-right italic w-px pt-4">Taggar: </td>
          <td>
            <vue-tags-input
              v-model="theModel.tag"
              :tags="tagTextObjects"
              :autocomplete-items="autoTags"
              @tags-changed="newTags => tags = newTags"
            />
          </td>
        </table>
      </div>
      
    </div>
    <button v-if="editMode && !removed && (newArticle || editArticle) && canAddArticle && ['admin', 'family'].includes(userLevel)" @click="saveArticle" class="bg-gray-100 border border-gray-500 rounded-xl text-gray-500 px-3 mx-2 mt-4">{{ saveButtonText }}</button>
    <button v-if="editMode && !removed && ['admin', 'family'].includes(userLevel)" @click="articleAction" class="button-dark">{{buttonText}}</button>
    <button v-if="editMode && !removed && editArticle && !newArticle && userLevel ==='admin'" @click="deleteAction" class="bg-gray-100 border border-gray-500 rounded-xl text-gray-500 px-3 mx-2 mt-4">Ta bort artikel</button>
  </div>
</template>


<script>
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import Tiptap from "./TipTap.vue"
import VueTagsInput from "@sipec/vue3-tags-input"
import apiCalls from '../services/apiCalls.js'
import Swal from 'sweetalert2'


export default {
  data() {
    return {
      tags: [],
      content: '<p>A Vue.js wrapper component for Tiptap to use <code>v-model</code></p>',
    }
  },
  props: {    
    modelValue: {
      type: Object
    },
    autoTags: {
      type: Array
    },
    options: {
      type: Array
    },
    editMode: {
      Boolean
    },
    newArticle: {
      Boolean
    },
    editArticle: {
      Boolean
    },
    removed: {
      Boolean
    },
    userLevel: {
      String
    },
  },
  emits: ['update:modelValue', 'toggleArticle', 'resetObject', 'getAll'],
  computed: {
    theModel: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
    canAddArticle: function() {
      // console.log("Size", new Blob([String(this.theModel.story)]).size)
      if (this.theModel.story.length > 40 &&
      this.headerOk && this.categoryOk) {
        console.log("Returning true from canAddArticle")
        return true
      }
      return false
    },
    validYear () {
      let year = this.theModel.year
      if (!year) {
        return false
      }
      if (!isNaN(year) && year.length === 4) {
        return true
      }
      if (!isNaN(year.substring(0,2)) && year.substring(2)==="-tal") {
        return true
      }
      if (!isNaN(year.substring(0,4)) && year.substring(4)==="-tal") {
        return true
      }

      return false
    },
    buttonText () {
      if (this.newArticle) {
        return "Stäng ny artikel"
      } else if (this.editArticle) {
        return "Stäng artikel"
      }
      return "Lägg till artikel"
    },
    headerOk () {
      return this.theModel.heading.length > 0
    },
    categoryOk () {
      return this.theModel.category.length > 0
    },
    saveButtonText () {
      if (this.theModel.id) {
        return "Spara artikel"
      } else {
        return "Lägg till ny artikel"
      }
    },
    showEditor() {
      return (this.editMode && this.newArticle)
    },
    tagTextObjects () {
      console.log("this.modelValue.tags", this.modelValue.tags)
      let tagTextObjects = this.modelValue.tags.map((tag) => {return {text: tag.text,}})
      console.log("tagTexts", tagTextObjects)
      return tagTextObjects
    },
    oldTagTexts () {
      return this.modelValue.tags.map(x => x.text.toLowerCase())
    },
    newTagTexts () {
      console.log("this.tags from newTagTexts", this.tags)
      return this.tags.map(x => x.text.toLowerCase())
    },
    addTags() {
      console.log("this.tags from addTags", this.tags)
      return this.tags.filter(x => !this.oldTagTexts.includes(x.text.toLowerCase()))
    },
    removeTags() {
      return this.modelValue.tags.filter(x => !this.newTagTexts.includes(x.text.toLowerCase()))
    },
  },
  watch: {
    editArticle(val) {
      if (val) {
        this.tags = this.modelValue.tags
      }
    }
  },
  components: {
    VueTagsInput,
    Tiptap
  },
  methods: {
    articleAction () {
      this.$emit("toggleArticle")
    },
    saveArticle () {
      let editObj = {...this.modelValue}
      if (!this.theModel.id) { 
        console.log("this.addTags from saveArticle", this.addTags)
        if (this.addTags.length > 0) {
          console.log("Setting editObj.tags = this.tags")
          editObj.tags = this.tags
        }
        apiCalls.addArticle(Swal, editObj).then(resp => {
          console.log("Article added", resp)
          let that = this
          setTimeout(function() {
            that.$emit("getAll", true)
            that.$emit("toggleArticle")
          }, 100);
        },
        err => {
          console.log("Could not add article", err)
        })
      } else {
        
        editObj.tags = null

        if (this.addTags.length > 0 || this.removeTags.length > 0) {
          console.log("this.addTags.length", this.addTags.length)
          console.log("this.removeTags.length", this.removeTags.length)
          console.log("adding tags")
          editObj.tags = this.tags
        } else {
          console.log("not adding tags")
        }

        console.log("editObj", editObj)

        apiCalls.editArticle(Swal, editObj).then(resp => {
          console.log("Article edited", resp)
          let that = this
          setTimeout(function() {
            that.$emit("getAll", true)
            that.$emit("toggleArticle")
          }, 100);
        },
        err => {
          console.log("Could not edit article", err)
        })
      }
    },
    deleteAction() { 
      Swal.fire({
            title: 'Är du säker på att du vill ta bort artikeln?',
            showCancelButton: true,
            confirmButtonText: 'Ja!',
            cancelButtonText: `Njae`,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            console.log("Swal result", result)
            if (result.value) {
              apiCalls.deleteArticle(Swal, this.modelValue.id).then(Swal => {
                  console.log("isConfirmed")
                  this.$emit("getAll")
                  Swal.fire('Saved!', '', 'success')
                }
              )
            } else if (result.dismiss === "cancel") {
              Swal.fire('Changes are not saved', '', 'info')
            }
          })
        
          return null
        }
  }, 
}
</script>
<style lang="postcss" scoped>
.inputok, .inputok:focus {
  @apply border-green-500 border-2 outline-none !important
}

</style>


